import i18n from '@/i18n'

enum MembershipToggle {
  monthly = 'monthly',
  annually = 'annually',
}

export const MembershipToggleTitle: any = {
  [MembershipToggle.annually]: i18n.t('Annually'),
  [MembershipToggle.monthly]: i18n.t('Monthly'),
}

export default MembershipToggle

export enum MembershipNames {
  membership_free = 'BASIC',
  membership_professional = 'PROFESSIONAL',
  membership_premium = 'PREMIUM',
  membership_professional_yearly = 'PROFESSIONAL',
  membership_premium_yearly = 'PREMIUM',
}
