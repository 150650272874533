import { AxiosResponse } from 'axios'
import BankAccountPayload from '@/shared/interfaces/payment/bank-account-payload'
import http from '../http'

export default class PaymentService {
  static async getBalance(): Promise<AxiosResponse> {
    return http.get('stripe/dashboard')
  }

  static async getPayouts(): Promise<AxiosResponse> {
    return http.get('stripe/payouts')
  }

  static async payout(data: any): Promise<AxiosResponse> {
    return http.put('stripe/payout', data)
  }

  static async isStripeAccountVerified(): Promise<AxiosResponse> {
    return http.post('stripe/account-verified')
  }

  static async createAccount(): Promise<AxiosResponse> {
    return http.post('stripe/create-account')
  }

  static async updateAccount(data: any = null): Promise<AxiosResponse> {
    return http.put('stripe/update-account', data)
  }

  static async getStripeAccountLink(data: any = null): Promise<AxiosResponse> {
    return http.put('stripe/get-account-link', data)
  }

  static async getStripePaymentMethods(): Promise<AxiosResponse> {
    return http.get('stripe/customer-payment-methods')
  }

  static async addBankAccount(data: BankAccountPayload): Promise<AxiosResponse> {
    return http.post('stripe/external-account', data)
  }

  static async deleteBankAccount(id: string): Promise<AxiosResponse> {
    return http.delete(`stripe/external-account/${id}`)
  }

  static async favouriteBankAccount(id: string): Promise<AxiosResponse> {
    return http.put(`stripe/external-account/set-default`, { id })
  }

  static async getBankAccountList(): Promise<AxiosResponse> {
    return http.get('stripe/external-account/list')
  }
}
