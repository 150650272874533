import { render, staticRenderFns } from "./PlanComponent.vue?vue&type=template&id=0c130601&"
import script from "./PlanComponent.vue?vue&type=script&lang=ts&"
export * from "./PlanComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports